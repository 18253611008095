import React, {createContext, useState} from "react";

export const AnimationContext = createContext<null | any>(null)

interface AnimationContextParams {
    children?: React.ReactNode
}

export const AnimationProvider: React.FC<AnimationContextParams> = ({children}) => {
    const [animeHeader, setAnimeHeader] = useState(false);
    return (
        <AnimationContext.Provider value={{
            animeHeader,
            setAnimeHeader
        }}>
            {children}
        </AnimationContext.Provider>
    )
}