import { KokotonLogo } from "../../app/assets/illustration";

export const projects = [
    {
        slug: "kokoton-identite-visuelle",
        name: "Coaching Kokoton Agence",
        slogan: "La réussite confiante et durable",
        client: "",
        date: "2021",
        Icon: KokotonLogo,
        description: "Coaching Kokoton Agence est une équipe pluridisciplinaire, multiculturelle et de l’excellence. Nous sommes des coachs, des historiens, des thérapeutes, des cadres de l’entreprise, des travailleurs sociaux et humanitaires pour accompagner les personnes et les institutions. Nous participons au développement des capacités avec des méthodologies associées à des groupes ou des personnes individuelles en développement actif, en ethno-thérapie et comportementale avec la prise en charge des TOCs (timidité, etc...), en art-thérapie, en coaching de vie et professionnel, social, parental, politique et culturel ... Nous aidons à mieux définir la cohérence des projets de vie ou professionnels, culturels, institutionnels, en définissant ensemble la personnalité et les capacités des personnes pour les motiver dans une démarche de réussite.",
        tags: [
            "conception de logo",
            "identité visuelle",
            "branding",
            "communication visuelle"
        ],
        url: "https://www.facebook.com/lamaisonbleuegoughin?mibextid=LQQJ4d",
        categories: [
            "Graphisme", "Conception d'identité visuelle"
        ],
        cover: "",
        images: [
            {

            }
        ]

    }
]