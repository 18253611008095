import React from 'react'

export function Logo() {
  return (
    <svg width="167" height="165" viewBox="0 0 167 165" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M127.034 33.8526V20.3931H149.978C155.816 20.3931 166.998 26.5111 166.998 35.62V163.145H151.198V148.734H150.588C149.368 156.756 147.539 164.641 142.224 164.641C138.39 164.641 127.556 168.81 127.556 148.734V64.8503L140.975 43.0975L143.937 41.602V34.8043C143.937 31.1335 142.369 29.0942 140.103 29.638L128.253 35.3481C127.469 35.756 127.034 35.0762 127.034 33.8526ZM151.198 49.8953L142.921 62.811V140.849C142.921 148.598 144.576 151.453 146.58 151.453C150.153 151.453 150.937 141.393 151.198 133.099V49.8953Z" fill="#171717"/>
        <path d="M107.526 163.145V20.3931H123.326V163.145H107.526Z" fill="#171717"/>
        <path d="M102.477 35.3481L90.8017 29.774C88.5363 29.0942 86.9679 31.1335 86.9679 34.8043V41.1942L103.349 62.5391C103.349 65.53 103.349 144.656 103.349 146.015C103.281 168.81 93.8223 164.641 88.5943 164.641C83.3664 164.641 81.4495 156.756 80.2296 148.734H79.7068C79.7068 149.006 79.7068 162.738 79.7068 163.145H63.4712V75.0922C63.4712 73.8686 63.994 73.0529 64.7782 73.0529H79.7068C79.7068 74.9562 79.7068 131.468 79.7068 133.099C79.9682 141.393 80.6653 151.453 84.2377 151.453C86.2418 151.453 87.9844 148.054 87.9844 138.402V66.8896L74.0432 47.72C71.6907 45.5447 70.7322 42.2818 70.7322 37.3874V33.5807C70.7322 25.9673 74.6532 20.3931 80.491 20.3931H103.697V33.8526C103.697 35.0762 103.174 35.756 102.477 35.3481Z" fill="#171717"/>
        <path d="M43.9633 163.145V20.3931H59.7633V163.145H43.9633Z" fill="#171717"/>
        <path d="M0 163.145V0H15.8V34.9403H16.3228C17.6298 26.919 20.3309 19.0336 25.5588 19.0336C29.3926 19.0336 39.8776 15.8613 39.8776 34.9403V163.145H24.0776V42.8257C24.0776 34.9403 22.2478 32.2212 20.418 32.2212C16.9327 32.2212 16.0614 42.2819 15.8 50.7111V163.145H0Z" fill="#171717"/>
    </svg>
  )
}
