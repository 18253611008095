import React, { useEffect } from 'react'
import DeviceDetector from "device-detector-js";

export const startCursor = () => {
  const cursor: any = document.querySelector(".hs-cursor-point");
  const cursorCircle: any = document.querySelector(".hs-cursor-circle");
  const deviceDetector: any = new DeviceDetector();
  const userAgent: any = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
  const device: any = deviceDetector.parse(userAgent);

  if(device?.device?.type === "mobile" || device?.device?.type === "tablet"){
      if(cursor) cursor.style.display = "none";
      if(cursorCircle) cursorCircle.style.display = "none";
  }

  if(document.querySelector(".hs-cursor-point.expand")){
    if(cursor) cursor.classList.remove("expand");
    if(cursorCircle) cursorCircle.classList.remove("expand");
  }
  if(document.querySelector(".hs-cursor-circle.hoverNavLink")){
    if(cursorCircle) cursorCircle.classList.remove("hoverNavLink");
  }
  
  document.addEventListener("mousemove", (e) => {
    if (cursor) cursor.setAttribute("style", `top: ${e.pageY}px; left: ${e.pageX}px`)
    if(cursorCircle) cursorCircle.setAttribute("style", `top: ${e.pageY - 12.5}px; left: ${e.pageX - 12.5}px`)
  });
  document.addEventListener("click", (e) => {
    if(cursorCircle) cursorCircle.classList.add("expand")
      setTimeout(() => {
        if(cursorCircle) cursorCircle.classList.remove("expand")
      }, 500)
  });
  
}


export function Cursor() {
  useEffect(() => {
    if(document.querySelector(".hs-cursor-point")){
      startCursor()
    }
  })
  return (
    <>
      <div className="hs-cursor-circle"></div>
      <div className="hs-cursor-point"></div>
    </>
  )
}
