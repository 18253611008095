import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

import "locomotive-scroll/src/locomotive-scroll.scss";
import LocomotiveScroll from "locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

export default function useLocoScroll(start: any) {
    useEffect(() => {
        if (!start) return;
        let locoScroll: any = null;

        const scrollEl: any | undefined = document.querySelector(".hs-main");


        locoScroll = new LocomotiveScroll({
            el: scrollEl,
            smooth: true,
            multiplier: 1,
            class: "is-reveal",
            // @ts-ignore
            mobile: {
                breakpoint: 0,
                smooth: true,
            },
            tablet: {
                breakpoint: 0,
                smooth: true,
            },
        });

        locoScroll.on("scroll", () => {
            ScrollTrigger.update();
        });

        ScrollTrigger.scrollerProxy(scrollEl, {
            scrollTop(value) {
                if (locoScroll) {
                    return arguments.length
                        ? locoScroll.scrollTo(value, 0, 0)
                        : locoScroll.scroll.instance.scroll.y;
                }
                return null;
            },
            scrollLeft(value) {
                if (locoScroll) {
                    return arguments.length
                        ? locoScroll.scrollTo(value, 0, 0)
                        : locoScroll.scroll.instance.scroll.x;
                }
                return null;
            },
        });

        const lsUpdate = () => {
            if (locoScroll) {
                locoScroll.update();
            }
        };

        ScrollTrigger.addEventListener("refresh", lsUpdate);
        ScrollTrigger.refresh();

        return () => {
            if (locoScroll) {
                ScrollTrigger.removeEventListener("refresh", lsUpdate);
                locoScroll.destroy();
                locoScroll = null;
                console.log("Kill", locoScroll);
            }
        };

    }, [start]);
}