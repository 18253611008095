import React, { useContext, useEffect, useRef, useState } from 'react'
import { AnimationContext } from '../../../../context/AnimationContext';
import useLocoScroll from '../../../../hooks/useLocoScroll';
import { Loader } from '../../hisia';
import { Footer } from '../../../../components';
import { gsap } from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export const ProjectsPage = () => {
  window.scrollTo(0, 0)
  const ref = useRef(null);
  const container_banner = useRef<any>(null)
  const [preloader, setPreload] = useState(true);

  const {animeHeader} = useContext(AnimationContext)
  useLocoScroll(!preloader);

  const hoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.toggle("scrolltobottom")
    }
  }

  const unhoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.remove("scrolltobottom")
    }
  }

  useEffect(() => {
    if (!preloader && ref) {
      if (typeof window === "undefined" || !window.document) {
          return;
      }
    }

    gsap.utils.toArray(".panel").forEach((panel: any, i: number) => {
      ScrollTrigger.create({
          trigger: panel,
          scroller: ".hs-main",
          start: "top top",
          pin: true,
          pinSpacing: false
      });
    });

  }, [preloader])

  const [timer, setTimer] = React.useState(!animeHeader ? 7 : 1);
  const id = useRef(null);
  const clear = () => {
    // @ts-ignore
    window.clearInterval(id.current);
    setPreload(false);
  };

  useEffect(() => {
    // @ts-ignore
    id.current = window.setInterval(() => {
        setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
        clear();
    }
  }, [timer]);

  if (typeof window === "undefined" || !window.document) {
    return null;
  }

  return (
    <> 
    {
      preloader ? (
        !animeHeader ? <Loader /> : null
      ) : (
        <main role='main' className='hs-main' data-scroll-container ref={ref} >
          <div className='hs-wait panel' onMouseEnter={() => hoverLink()} onMouseLeave={() => unhoverLink()} ref={container_banner}>
            <h1>Page en maintenance</h1>
            <p>Nous travaillons activement pour rendre disponible cette page...</p>
          </div>
          <Footer/>
        </main>
      )
    }
    </>
  )
}
