import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes } from '../constant'

export function WebRouter() {
  return (
    <Routes>
      {
        routes.map(({path, Component}, index) => (
          <Route path={path} element={<Component/>} key={index}/>
        ))
      }
    </Routes>
  )
}
