import React, { useContext, useEffect, useRef, useState } from 'react'
import {gsap} from "gsap"
import {useGSAP} from "@gsap/react"
import ScrollTrigger from "gsap/ScrollTrigger";
import { convertStringToArray } from '../../../utils';
import { AnimationContext } from '../../../context/AnimationContext';
import useLocoScroll from '../../../hooks/useLocoScroll';
import useOnScreen from '../../../hooks/useOnScreen';
import { Footer } from '../../../components';

gsap.registerPlugin(ScrollTrigger);

const Loader = () => {
  const {setAnimeHeader} = useContext(AnimationContext)
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const container = useRef<any>(null)
  const tl = useRef<any>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setLoading2(false);
      setAnimeHeader(true);
    }, 7000);

    return () => clearTimeout(timer2);
  }, [setAnimeHeader]);

  useGSAP(() => {
    tl.current = gsap.timeline({paused: true})
                      .to(".hs-loader-item .hs-blanc", {
                          duration: 1,
                          scaleY: 0,
                          ease: "power4.inOut"
                      })
                      .to(".hs-loader-item .hs-noir", {
                        duration: 1.1,
                        scaleY: 0,
                        ease: "power4.inOut",
                        delay: -0.65
                      })
                      .to(".hs-loader-item img", {
                        duration: 1.2,
                        scale: 1.2,
                        ease: "power4.inOut",
                        delay: -0.95
                      })
                      .to(".hs-loader-title h1 span", {
                        duration: 1.2,
                        y: 0,
                        ease: "power4.inOut",
                        delay: -1.2,
                        stagger: 0.05, 
                      })
  }, {scope: container})

  useEffect(() => {
    if(loading){
      tl.current.play()
    }else{
      tl.current.reverse()
    }
  }, [loading])

  return (
    <div className={`hs-loader ${loading2 ? "" : "is--disappear"}`} ref={container}>
      <div className='hs-loader-grid'>
        <div className='hs-loader-item'>
          <img src="/images/normal/2149837027.jpg" alt="item" />
          <div className='hs-blanc'></div>
          <div className='hs-noir'></div>
        </div>
        <div className='hs-loader-item'>
          <img src="/images/normal/2151238507.jpg" alt="item" />
          <div className='hs-blanc'></div>
          <div className='hs-noir'></div>
        </div>
        <div className='hs-loader-item'>
          <img src="/images/normal/2151230078.jpg" alt="item" />
          <div className='hs-blanc'></div>
          <div className='hs-noir'></div>
        </div>
        <div className='hs-loader-item'>
          <img src="/images/normal/image3.jpg" alt="item" />
          <div className='hs-blanc'></div>
          <div className='hs-noir'></div>
        </div>
      </div>
      <div className='hs-loader-title'>
        <h1>
          {
            convertStringToArray("Vous rendre unique!").map((word, index) => (
              <span style={{margin: word !== " " ? 0 : ".2em"}} key={index}>{word}</span>
            ))
          }
        </h1>
      </div>
    </div>
  )
}

const Introduce = () => {
  const container = useRef<any>(null)
  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(container);

  const hoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.toggle("hoverNavLink2")
    }
  }

  const unhoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.remove("hoverNavLink2")
    }
  }
  useEffect(() => {
    setReveal(onScreen);
  }, [onScreen]);

  useEffect(() => {
      if (reveal) {
          gsap.to(".hs-home-introduce__container h1 p", {
              duration: 1,
              opacity: 1,
              stagger: 0.2,
              ease: "power4.out",
              // onComplete: () => split.revert(),
          });
      }else{
          let spans: any = document.querySelector(".hs-home-introduce__container h1 p")
          spans && (spans.style.opacity = 0.1)
      }
  }, [reveal]);
  return (
    <div className='hs-home-introduce' ref={container}>
      <div className='hs-home-introduce__container'>
        <h1>
          <p data-scroll data-scroll-speed="2">Créer et partager de <strong onMouseEnter={() => hoverLink()} onMouseLeave={() => unhoverLink()}>l’émotion</strong>. <strong onMouseEnter={() => hoverLink()} onMouseLeave={() => unhoverLink()}>Hisia Studio</strong>  est un groupe</p>
          <p data-scroll data-scroll-speed="2">qui utilise sa <strong onMouseEnter={() => hoverLink()} onMouseLeave={() => unhoverLink()}>créativité et ses compétences</strong> dans le </p>
          <p data-scroll data-scroll-speed="2">domaine du numérique et artistique pour vous offrir des </p>
          <p data-scroll data-scroll-speed="2">solutions qui vous permettront de vous <strong onMouseEnter={() => hoverLink()} onMouseLeave={() => unhoverLink()}>démarquer et d’être unique.</strong></p>
        </h1>
      </div>
    </div>
  )
}

export const HomePage = () => {
  window.scrollTo(0, 0)
  const ref = useRef(null);
  const container_banner = useRef<any>(null)
  const [preloader, setPreload] = useState(true);

  const {animeHeader} = useContext(AnimationContext)
  useLocoScroll(!preloader);

  const hoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.toggle("scrolltobottom")
    }
  }

  const unhoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.remove("scrolltobottom")
    }
  }
  
  useEffect(() => {
    if (!preloader && ref) {
      if (typeof window === "undefined" || !window.document) {
          return;
      }
    }

    gsap.utils.toArray(".panel").forEach((panel: any, i: number) => {
      ScrollTrigger.create({
          trigger: panel,
          scroller: ".hs-main",
          start: "top top",
          pin: true,
          pinSpacing: false
      });
    });

    let trigger: any = document.querySelector(".hs-home-banner")
    let square: any = document.querySelector(".hs-home-banner-image.mid")
    let square2: any = document.querySelector(".hs-home-banner-image.one")
    let square3: any = document.querySelector(".hs-home-banner-image.two")
    let trigger2: any = document.querySelector(".hs-home-about")
    let about_image: any = document.querySelector(".hs-home-about-image")
    let about_contain: any = document.querySelector(".hs-home-about-container")
    let about_contain_p: any = document.querySelectorAll(".hs-home-about-container p")
   
    if(trigger){
      gsap.set(".hs-home-banner-container strong", {opacity: 0})
      gsap.set(".hs-home-banner-container h1 span", {y: "20svh", opacity: 0})

      gsap.to(".hs-home-banner-container strong", {
        delay: 0.5,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
      })

      gsap.to(".hs-home-banner-container h1 span", {
        delay: 0.8,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
        stagger: .1,
        y: 0
      })

      gsap.to(".hs-home-banner .hs-blanc", {
        delay: 1,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
        stagger: .1,
        scaleY: 0
      })
      gsap.to(".hs-home-banner .hs-noir", {
        delay: .9,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
        stagger: .2,
        scaleY: 0
      })

      gsap.to(square, {
        height: '100svh',
        width: '100%',
        maxWidth: '100%',
        y: "-30svh",
        ease: "none",
        scrollTrigger: {
          start: "top top",
          trigger: trigger,
          scroller: ".hs-main",
          pin: true,
          scrub: true,
          end: () => `+=50svh`,
        }
      })
      gsap.to(square2, {
        height: 0,
        width: 0,
        maxWidth: 0,
        ease: "none",
        scrollTrigger: {
          start: "top top",
          trigger: trigger,
          scroller: ".hs-main",
          pin: true,
          scrub: true,
          end: () => `+=20svh`,
        }
      })
      gsap.to(square3, {
        height: 0,
        width: 0,
        maxWidth: 0,
        ease: "none",
        scrollTrigger: {
          start: "top top",
          trigger: trigger,
          scroller: ".hs-main",
          pin: true,
          scrub: true,
          end: () => `+=20svh`,
        }
      })
    }

    if(trigger2){

      gsap.to(about_image, {
        height: '75svh',
        width: '40%',
        maxWidth: '40%',
        ease: "none",
        scrollTrigger: {
          start: "top top",
          trigger: trigger2,
          scroller: ".hs-main",
          pin: true,
          scrub: true,
          end: () => `+=70svh`,
        }
      })
      gsap.to(about_contain, {
        opacity: 1,
        x: 0,
        ease: "none",
        scrollTrigger: {
          start: "top top",
          trigger: trigger2,
          scroller: ".hs-main",
          pin: true,
          scrub: true,
          end: () => `+=80svh`,
        }
      })
      gsap.to(about_contain_p, {
        x: 0,
        opacity: 1,
        ease: "none",
        stagger: .1,
        scrollTrigger: {
          start: "top top",
          trigger: trigger2,
          scroller: ".hs-main",
          pin: true,
          scrub: true,
          end: () => `+=70svh`,
        }
      })
    }
  }, [preloader])

  useEffect(() => {
    let items: any = document.querySelectorAll(".hs-home-services__item")
    items.forEach((el:any, index:number) => {
      
        const color = el.getAttribute("data-color");
        el.addEventListener("mouseenter", (e: any) => {
            let bg: any = document.querySelector(".hs-home-services")
            bg && (bg.style.background = color)
            el.querySelector("strong").setAttribute("style","z-index: 3")
            el.querySelector("span").setAttribute("style","z-index: 3")
        })
        el.addEventListener("mouseleave", (e: any) => {
            el.querySelector("strong").setAttribute("style","z-index: 1")
            el.querySelector("span").setAttribute("style","z-index: 1")
            let bg: any = document.querySelector(".hs-home-services")
            bg && (bg.style.background = "#000")
        })
        el.addEventListener("mousemove", (e: any) => {
            const position = el.getBoundingClientRect();
            const x = (e.pageX - position.left - position.width / 3) / 50;
            const y = (e.pageY - position.top - position.height / 3) / 100;
            const tx =  x;
            const ty =  y;
            el.querySelector(".hover-reveal").setAttribute("style",`top: ${120 - ty}%; right: ${10 - tx}%; transform: translate(${-1 * (50 - tx)}%, ${-1 * (100 - ty)}%) rotate(${tx}deg)`)
            
        })
        el.addEventListener("mouseout", (e: any) => {
            el.querySelector(".hover-reveal").setAttribute("style",`top: 0; right: 0; transform: translate(0, 0) rotate(0deg)`)
        })
    })
   
})

  const [timer, setTimer] = React.useState(!animeHeader ? 7 : 1);
  const id = useRef(null);
  const clear = () => {
      // @ts-ignore
      window.clearInterval(id.current);
      setPreload(false);
  };

  useEffect(() => {
      // @ts-ignore
      id.current = window.setInterval(() => {
          setTimer((time) => time - 1);
      }, 1000);
      return () => clear();
  }, []);

  useEffect(() => {
      if (timer === 0) {
          clear();
      }
  }, [timer]);


  if (typeof window === "undefined" || !window.document) {
      return null;
  }

  return (
    <>
      {
        preloader ? (
          !animeHeader ? <Loader /> : null
        ) : (
          <main role='main' className='hs-main' data-scroll-container ref={ref} >
            <div className='hs-home' >
              <div className='hs-home-banner' onMouseEnter={() => hoverLink()} onMouseLeave={() => unhoverLink()} ref={container_banner}>
                 <div className='hs-home-banner-container'>
                    <strong>Bienvenue sur Hisia</strong>
                    <h1>
                      {
                        convertStringToArray("Vous rendre unique!").map((word, index) => (
                          <span style={{margin: word !== " " ? 0 : ".2em"}} key={index}>{word}</span>
                        ))
                      }
                    </h1>
                  </div>
                  <div className='hs-home-banner-images'>
                    <div className='hs-home-banner-image not-mid one'>
                      <div className='hs-home-banner-image-contain' >
                        <img src="/images/normal/2149837006.jpg" data-scroll data-scroll-speed="-2" alt="banner-1" />
                      </div>
                      <div className='hs-noir'></div>
                      <div className='hs-blanc'></div>
                    </div>
                    <div className='hs-home-banner-image mid'>
                      <div className='hs-home-banner-image-contain'>
                        <img src="/images/normal/2151230078.jpg" data-scroll data-scroll-speed="-2" alt="banner-2" />
                      </div>
                      <div className='hs-noir'></div>
                      <div className='hs-blanc'></div>
                    </div>
                    <div className='hs-home-banner-image not-mid two'>
                      <div className='hs-home-banner-image-contain'>
                        <img src="/images/normal/2151238507.jpg" data-scroll data-scroll-speed="-2" alt="banner-3" />
                      </div>
                      <div className='hs-noir'></div>
                      <div className='hs-blanc'></div>
                    </div>
                  </div>
              </div>
              <Introduce/>
              <div className='hs-home-about'>
                <div className='hs-home-about-image'>
                  <img src="/images/normal/37923.jpg"  data-scroll data-scroll-speed="2" alt="about" />
                </div>
                <div className='hs-home-about-container'>
                  <div className='hs-home-about-text'>
                    <h1>
                      <p>Un studio pour rafraichir votre image de marque</p>
                      <p>Nous utilisons notre créativité, nos compétences</p>
                      <p>dans le digital(numérique) et le marketing pour vous</p>
                      <p>une identité différente, unique qui vous corresponde le mieux.</p>
                    </h1>
                  </div>
                </div>
              </div>
              <div className='hs-home-services'>
                <div className='hs-home-services__container'>
                  <div className='hs-home-services__head'>
                      <h2>Nos Services</h2>
                      <h1>Que faisons-nous?</h1>
                  </div>
                  <div className='hs-home-services__list hs-home-services-list'>
                    <div className='hs-home-services__item' data-image={"/images/normal/2149837006.jpg"} data-color={"#2a170d"}>
                        <span>01</span>
                        <strong>Communication visuelle</strong>
                        <div className="hover-reveal">
                            <div className='hover-reveal__inner'>
                              <img src={"/images/normal/2149837006.jpg"} alt="branding" className='hover-reveal__img' />
                            </div>
                        </div>
                    </div>

                    <div className='hs-home-services__item' data-image={"/images/normal/14223.jpg"} data-color={"#051b2e"}>
                        <span>02</span>
                        <strong>UI/UX Design</strong>
                        <div className="hover-reveal">
                            <div className='hover-reveal__inner'>
                              <img src={"/images/normal/14223.jpg"} alt="design" className='hover-reveal__img' />
                            </div>
                        </div>
                    </div>

                    <div className='hs-home-services__item' data-image={"/images/normal/image6.jpg"} data-color={"#2e0531"}>
                        <span>03</span>
                        <strong>Développement Web et Mobile</strong>
                        <div className="hover-reveal">
                            <div className='hover-reveal__inner'>
                              <img src={"/images/normal/image6.jpg"} alt="design" className='hover-reveal__img' />
                            </div>
                        </div>
                    </div>


                    <div className='hs-home-services__item' data-image={"/images/normal/video.jpg"} data-color={"#2c3844"}>
                        <span>04</span>
                        <strong>Community Management</strong>
                        <div className="hover-reveal">
                            <div className='hover-reveal__inner'>
                              <img src={"/images/normal/video.jpg"} alt="design" className='hover-reveal__img' />
                            </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              
              <div className='hs-home-bg ' >
                <img src="/images/normal/2151238507.jpg" data-scroll data-scroll-speed="-2" alt="bg" />
              </div>
            
              <div className='hs-home-studio'>
                  <div className='hs-home-studio__container'>
                    <h1>Nous vous mettons au coeur de notre collaboration.</h1>
                  </div>
                  <div className='hs-home-studio__image'>
                    <img src="/images/normal/image7.jpg" data-scroll data-scroll-speed="-2" alt="bg" />
                  </div>
              </div>
              <Footer/>
            </div>
          </main>
        )
      }
    </>
  )
}
