import {useEffect, useState} from "react";

function useOnScreen (ref: any, rootMargin?: string | "0px") {
    const [intersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry?.isIntersecting ?? false)
        }, {
            rootMargin,
            threshold: 0.5
        })
        const currentRef = ref.current

        if(currentRef){
            observer.observe(currentRef)
        }

        return () => {
            if(currentRef){
                observer.unobserve(currentRef)
            }
        }
    }, [ref, rootMargin])

    return intersecting
}

export default useOnScreen