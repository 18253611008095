
export const capitalize = (word : string) => {
    const lower = word.toLowerCase()
    return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const titlePage = (title?: string) => {
    if(title){
        document.title = capitalize(title) + " | Hisia Studio";
    }else{
        document.title = "Bienvenue sur Hisia"
    }
}

export const convertStringToArray = (word: string) => {
    return word.split("");
}

export const getProject = (slug: string, projects: any[]) => {
    return projects.filter((el: any) => el.slug === slug)[0]
}
export const getNextProject = (project: string, projects: any[]) => {
    let elementIndex: number = projects.indexOf(project)
    if(elementIndex < projects.length){
        return projects[elementIndex + 1]
    }
    return null
}

export const getPreviewProject = (project: string, projects: any[]) => {
    let elementIndex: number = projects.indexOf(project)
    if(elementIndex > 0){
        return projects[elementIndex - 1]
    }
    return null
}