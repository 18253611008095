
import React, { useContext, useEffect, useRef, useState } from 'react'
import { links } from '../../../routes'
import { Logo } from '../../../assets/illustration'
import { Link, NavLink } from 'react-router-dom'
import { email_link, facebook_link, insta_link, tel_one, tel_two, youtube_link } from '../../../../data';
import {gsap} from "gsap"
import {useGSAP} from "@gsap/react"
import { AnimationContext } from '../../../context/AnimationContext';

export function Header() {
  const [openMenu, setOpenMenu] = useState(false);
  const {animeHeader} = useContext(AnimationContext)
  const navItems = [
    {
      path: links.home,
      label: "Accueil",
      description: "Bienvenue sur Hisia"
    },
    {
      path: links.studio,
      label: "Le Studio",
      description: "Qui sommes-nous"
    },
    {
      path: links.works,
      label: "Nos travaux",
      description: "Hisia Labs"
    },
    {
      path: links.contact,
      label: "Nos contacts",
      description: "Nous réjoindre"
    },
  ]
  const container = useRef<any>(null)
  const tl = useRef<any>(null)
  const tl2 = useRef<any>(null)

  useGSAP(() => {
    gsap.set(".hs-navigation-item-link", {y: 100})
    tl.current = gsap.timeline({paused: true})
    .to(".hs-navigation", {
      duration: 1,
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: "power4.inOut"
    })
    .to(".hs-navigation-item-link", {
      duration: 1,
      y: 0,
      stagger: .1,
      ease: "power4.inOut",
      delay: -1.5
    })
   
    gsap.set(".hs-header-logo svg path", {x: -10, opacity: 0})
    gsap.set(".hs-header-burger span", {opacity: 0})
    gsap.set(".hs-header-burger .hs-header-burger-line", {opacity: 0})

    tl2.current = gsap.timeline({paused: true})
    .to(".hs-header-logo svg path", {
      duration: 1,
      x: 0,
      opacity: 1,
      stagger: .1,
      ease: "power4.inOut",
      delay: -.2
    })
    .to(".hs-header-burger span", {
      duration: 1,
      x: 0,
      opacity: 1,
      ease: "power4.inOut",
      delay: -.2
    })
    .to(".hs-header-burger .hs-header-burger-line", {
      duration: 1,
      opacity: 1,
      stagger: .1,
      ease: "power4.inOut",
      delay: -.2
    })
  }, {scope: container})

  useEffect(() => {
    if(openMenu){
      tl.current.play()
    } else {
      tl.current.reverse()
    }
  }, [openMenu])

  useEffect(() => {
    if(animeHeader){
      tl2.current.play()
    }else{
      gsap.set(".hs-header-logo svg path", {x: 0, opacity: 1})
      gsap.set(".hs-header-burger span", {x: 0, opacity: 1})
      gsap.set(".hs-header-burger .hs-header-burger-line", {opacity: 1})
    }
  }, [animeHeader])

  const hoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.toggle("hoverNavLink")
    }
  }

  const unhoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.remove("hoverNavLink")
    }
  }

  return (
    <div className='hs-menu' ref={container}>
      <header className='hs-header'>
        <div className='hs-header-container'>
          <div className='hs-header-logo'>
            <Link to={links.home} onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>
              <Logo/>
            </Link>
          </div>
          <button type='button' className={`hs-header-burger ${openMenu ? "is--active" : ""}`} onClick={() => setOpenMenu(!openMenu)} onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>
              <div className='hs-header-burger-lines'>
                <div className='hs-header-burger-line not-mid'></div>
                <div className='hs-header-burger-line mid'></div>
                <div className='hs-header-burger-line not-mid'></div>
              </div>
              <p>
                <span>{openMenu ? "Fermer" : "Menu"}</span>
              </p>
          </button>
        </div>
      </header>
      <nav className='hs-navigation'>
        <div className='hs-navigation-container'>
          <ul className='hs-navigation-list'>
            {
              navItems.map(({path, label}, index) => (
                <li className='hs-navigation-item' key={index} onClick={() => setOpenMenu(!openMenu)}>
                  <NavLink 
                    to={path} 
                    className={"hs-navigation-item-link"}
                    onMouseLeave={() => unhoverLink()} 
                    onMouseEnter={() => hoverLink()}
                    end >
                    <span>{label}</span>
                    <div className='hs-arrow'>
                      <div className='hs-arrow__container'></div>
                    </div>
                  </NavLink>
                </li>
              ))
            }
          </ul>
          <div className='hs-navigation-footer'>
            <div className='hs-navigation-footer-links'>
              <div className='hs-navigation-footer-contacts'>
                <h2>(C)</h2>
                <div className='hs-navigation-footer-contacts-list'>
                  <div className='hs-navigation-footer-contact'>
                    <a href={`mailto:${email_link}`} target='_blank' rel='noreferrer' className='hs-navigation-footer-link' onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}><span>Email:</span> <strong>{email_link}</strong></a>
                  </div>
                  <div className='hs-navigation-footer-contact'>
                    <p className='hs-navigation-footer-link'><span>Téléphone:</span> <strong>{tel_one + " / "+ tel_two}</strong></p>
                  </div>
                  <div className='hs-navigation-footer-contact'>
                    <p className='hs-navigation-footer-link'>
                      <strong>©Hisia Studio from Hisia Group</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className='hs-navigation-footer-socials'>
                <h2>(S)</h2>
                <div className='hs-navigation-footer-socials-list'>
                  <div className='hs-navigation-footer-social'>
                    <a href={`${facebook_link}`} target='_blank' rel='noreferrer' className='hs-navigation-footer-link' onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}><strong>Facebook</strong></a>
                  </div>
                  <div className='hs-navigation-footer-social'>
                    <a href={`${insta_link}`} target='_blank' rel='noreferrer' className='hs-navigation-footer-link' onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}><strong>Instagram</strong></a>
                  </div>
                  <div className='hs-navigation-footer-social'>
                    <a href={`${youtube_link}`} target='_blank' rel='noreferrer' className='hs-navigation-footer-link' onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}><strong>Youtube</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}
