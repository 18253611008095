import React from 'react'
import { links } from '../../../routes'
import { Link } from 'react-router-dom'
import { email_link, facebook_link, insta_link, tel_one, tel_two, youtube_link } from '../../../../data'

export function Footer() {
  const hoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.toggle("hoverNavLink")
    }
  }

  const unhoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.remove("hoverNavLink")
    }
  }
  return (
    <footer className='hs-footer'>
        <div className='hs-footer__container'>
            <div className='hs-footer__work'>
                <h1>
                    <Link to={links.contact}  onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>
                        Travaillons ensemble
                    </Link>
                </h1>
            </div>
            <div className='hs-footer__bottom'>
                <ul className='hs-footer__social hs-social-list'>
                    <li className='hs-social-item'>
                        <a href={facebook_link} target={"_blank"} rel="noreferrer"  onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>Facebook</a>
                    </li>
                    <li className='hs-social-item'>
                        <a href={youtube_link} target={"_blank"} rel="noreferrer"  onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>YouTube</a>
                    </li>
                    <li className='hs-social-item'>
                        <a href={insta_link} target={"_blank"} rel="noreferrer"  onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>Instagram</a>
                    </li>
                    <li className='hs-social-item'></li>
                </ul>
                <div className='hs-copyright'>
                    <div className="hs-copyright__work">
                        <p>Contacter-nous par mail: <a href={`mailto:${email_link}`}  onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>{email_link}</a></p>
                        <p>Contacter-nous par tel: <strong  onMouseLeave={() => unhoverLink()} onMouseEnter={() => hoverLink()}>{tel_one + " / "+ tel_two}</strong></p>
                    </div>
                    <strong>©Hisia Studio from Hisia Group</strong>
                </div>
            </div>
        </div>
    </footer>
  )
}
