import React from 'react';
import { WebRouter } from './app/routes';
import { Cursor, Header } from './app/components';
function App() {
  return (
    <div className="hs-app">
      <Header/>
      <WebRouter/>
      <Cursor/>
    </div>
  );
}

export default App;
