import React, { useContext, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import {useGSAP} from "@gsap/react"
import ScrollTrigger from "gsap/ScrollTrigger";
import useLocoScroll from '../../../hooks/useLocoScroll';
import { AnimationContext } from '../../../context/AnimationContext';
import { Logo } from '../../../assets/illustration';
import { Footer } from '../../../components';

gsap.registerPlugin(ScrollTrigger);

export const Loader = () => {
  const {setAnimeHeader} = useContext(AnimationContext)
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const container = useRef<any>(null)
  const tl = useRef<any>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setLoading2(false);
      setAnimeHeader(true);
    }, 7000);

    return () => clearTimeout(timer2);
  }, [setAnimeHeader]);

  useGSAP(() => {
    gsap.set(".hs-loader-about svg path", {x: -50, opacity: 0})
    tl.current = gsap.timeline({paused: true})
                      .to(".hs-loader-about svg path", {
                          duration: 1.5,
                          x: 0,
                          opacity: 1,
                          ease: "back.out",
                          stagger: .3
                      })
  }, {scope: container})

  useEffect(() => {
    if(loading){
      tl.current.play()
    }else{
      tl.current.reverse()
    }
  }, [loading])

  return (
    <div className={`hs-loader-about ${loading2 ? "" : "is--disappear"}`} ref={container}>
      <div className='hs-loader-about__container'>
        <Logo/>
      </div>
    </div>
  )
}


const Service = () => {
  useEffect(() => {
    let items = document.querySelectorAll(".hs-about-service__item")
      items.forEach((el: any, index: number) => {
          el.addEventListener("mouseenter", (e: any) => {
             el.querySelector(".hs-about-service__item--link").style.zIndex = 2;
             
             let color =  el.getAttribute("data-color");
             let body: any = document.querySelector(".hs-about-service")
             body && (body.style.background = color)
          })

          el.addEventListener("mouseleave", (e: any) => {
              el.querySelector(".hs-about-service__item--link").style.zIndex = 1;
              let body: any = document.querySelector(".hs-about-service")
              body && (body.style.background = "#E6EBD8")
              
          })
          el.addEventListener("mousemove", (e: any) => {
              const position = el.getBoundingClientRect();
              const x = (e.pageX - position.left - position.width / 3) / 50;
              const y = (e.pageY - position.top - position.height / 3) / 100;
              const tx =  x;
              const ty =  y;
              el.querySelector(".hs-about-service__item--img").setAttribute("style",`top: ${120 - ty}%; right: ${10 - tx}%; transform: translate(${-1 * (50 - tx)}%, ${-1 * (100 - ty)}%) rotate(${tx}deg)`)
              
          })
          el.addEventListener("mouseout", (e: any) => {
              el.querySelector(".hs-about-service__item--img").setAttribute("style",`top: 0; right: 0; transform: translate(0, 0) rotate(0deg)`)
              
          })
      })
  })
  return (
      <section className='hs-about__service hs-about-service'>
          <div className='hs-about-service__container'>
              <div className='hs-about-service__head'>
                  <h2>Expertise</h2>
                  <p>Passionnés par le design,le développement, et le marketing. Nous nous mettons à votre disposition pour satisfaire vos demandes et de vous rendre unique.</p>
                  
              </div>
              <div className='hs-about-service__list'>
                  
                  <div className='hs-about-service__item' data-image={"/images/normal/2149837006.jpg"} data-color={"#2a170d"}>
                      <div className="hs-about-service__item--link" >
                          Communication visuelle
                      </div>
                      <div className='hs-about-service__item--img'>
                          <img src={"/images/normal/2149837006.jpg"} alt='branding' />
                      </div>
                  </div>
                  <div className='hs-line'></div>
                  <div className='hs-about-service__item'  data-image={"/images/normal/14223.jpg"} data-color={"#051b2e"}>
                      <div className="hs-about-service__item--link" >
                          UI/UX Design
                      </div>
                      <div className='hs-about-service__item--img'>
                          <img src={"/images/normal/14223.jpg"} alt='design' />
                      </div>
                  </div>
                  <div className='hs-line'></div>
                  <div className='hs-about-service__item'  data-image={"/images/normal/image6.jpg"} data-color={"#2e0531"}>
                      <div className="hs-about-service__item--link" >
                          Développement Web et Mobile
                      </div>
                      <div className='hs-about-service__item--img'>
                          <img src={"/images/normal/image6.jpg"} alt='web' />
                      </div>
                  </div>
                  <div className='hs-line'></div>
                  <div className='hs-about-service__item' data-image={"/images/normal/video.jpg"} data-color={"#2c3844"}>
                      <div className="hs-about-service__item--link" >
                          Community Management
                      </div>
                      <div className='hs-about-service__item--img'>
                          <img src={"/images/normal/video.jpg"} alt='community' />
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

const Expertise = () => {
  return (
      <section className='hs-about__expertise hs-about-expertise'>
          <div className='hs-about-expertise__container'>
          <h2><strong>Hisia Studio</strong></h2>
              <h1>Vous mettre au coeur de notre collaboration</h1>
              <p>Pour atteindre notre objectif, Vous permettre de vous différencier des autres, il est de notre obligation de vous mettre au centre de notre travail. Nous nous mettons à votre disposition et sommes à votre écoute tout au long de notre collaboration.</p>
          </div>
          <div className='hs-about-expertise__img'>
              <img src={"/images/normal/image3.jpg"} alt="about" data-scroll data-scroll-speed="1"/>
          </div>
      </section>
  )
}

const Emotion = () => {
  return (
      <section className='hs-about__emotion hs-about-emotion'>
          <div className='hs-about-emotion__container'>
              <h2><strong>Mission</strong></h2>
              <h1>Créer et partager de l'émotion</h1>
              <p>Nous créons et animons des histoires ayant pour objectif de vous faire ressentir des émotions: de la joie, de la tristesse, de l'empathie; et de vous faire passer de bon moment ou à plusieurs.</p>
          </div>
          <div className='hs-about-emotion__img'>
              <img src={"/images/normal/image7.jpg"} alt="about" data-scroll data-scroll-speed="-1"/>
          </div>
      </section>
  )
}


const WeAre = () => {
  return (
      <section className='hs-about__weare hs-about-weare'>
          <div className='hs-about-weare__container'>
              <h1 >Nous Sommes</h1>
          </div>
      </section>
  )
}

const Quality = () => {
  return (
      <section className='hs-about__quality hs-about-quality '>
          <div className='hs-about-quality__container'>
              <div className='hs-about-quality__section'>
                  <div className='hs-about-quality__box hs-about-quality-box'>
                      <div className="hs-about-quality-box__img">
                          <img src={"/images/normal/about1.jpg"} alt="branding"  data-scroll data-scroll-speed="-3"/>
                      </div>
                      <div className="hs-about-quality-box__text">
                          <h2><span>01</span> <strong>Dynamiques</strong></h2>
                          <p>Notre équipe dynamique repousse les limites de la technologie pour offrir des solutions sur mesure qui propulsent votre entreprise vers de nouveaux sommets.</p>
                      </div>
                  </div>
                  <div className='hs-about-quality__box hs-about-quality-box'>
                      <div className="hs-about-quality-box__img">
                          <img src={"/images/normal/about4.jpg"} alt="branding"  data-scroll data-scroll-speed="-3"/>
                      </div>
                      <div className="hs-about-quality-box__text">
                          <h2><span>03</span> <strong>Créatifs</strong></h2>
                          <p>Nous combinons expertise technique et créativité pour offrir des solutions digitales qui captivent et engagent votre audience.</p>
                      </div>
                  </div>
              </div>
              <div className='hs-about-quality__section'>
                  <div className='hs-about-quality__box hs-about-quality-box'>
                      <div className="hs-about-quality-box__img">
                          <img src={"/images/normal/about3.jpg"} alt="branding" data-scroll data-scroll-speed="-3"/>
                      </div>
                      <div className="hs-about-quality-box__text">
                          <h2><span>02</span> <strong>Passionnés</strong></h2>
                          <p>Avec une approche proactive et une passion pour l'innovation, nous vous aidons à naviguer dans le monde numérique en constante évolution.</p>
                      </div>
                  </div>
                  <div className='hs-about-quality__box hs-about-quality-box'>
                      <div className="hs-about-quality-box__img">
                          <img src={"/images/normal/about2.jpg"} alt="branding" data-scroll data-scroll-speed="-3"/>
                      </div>
                      <div className="hs-about-quality-box__text">
                          <h2><span>04</span> <strong>A votre service</strong></h2>
                          <p>Réactifs, flexibles et toujours en quête de perfection, nous adaptons nos services à vos besoins pour garantir votre succès dans un monde digital dynamique.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}


const Value = () => {
  return (
      <section className='hs-about__value hs-about-value'>
          <div className='hs-about-value__container'>
              <div className='hs-about-value__text'>
                  <p>
                      Nous travaillons à vous offrir des solutions uniques et créatives vous permettant de vous démarquer des autres.
                  </p>
              </div>
          </div>
      </section>
  )
}


export const HisiaPage = () => {
  window.scrollTo(0, 0)
  const ref = useRef(null);
  const container_banner = useRef<any>(null)
  const [preloader, setPreload] = useState(true);

  const {animeHeader} = useContext(AnimationContext)
  useLocoScroll(!preloader);
  
  const hoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.toggle("scrolltobottom")
    }
  }

  const unhoverLink = () => {
    const circle = document.querySelector(".hs-cursor-circle")
    if(circle){
      circle.classList.remove("scrolltobottom")
    }
  }
  
  useEffect(() => {
    if (!preloader && ref) {
      if (typeof window === "undefined" || !window.document) {
          return;
      }
    }

    let trigger: any = document.querySelector(".hs-about-banner")
    let square: any = document.querySelector(".hs-about-banner__image")

    if(trigger){
      gsap.set(".hs-about-banner strong", {opacity: 0})
      gsap.set(".hs-about-banner span", {opacity: 0, y: 30})

      gsap.utils.toArray(".panel").forEach((panel: any, i: number) => {
        ScrollTrigger.create({
            trigger: panel,
            scroller: ".hs-main",
            start: "top top",
            pin: true,
            pinSpacing: false
        });
      });


      gsap.to(".hs-about-banner .hs-blanc", {
        delay: 1,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
        stagger: .1,
        scaleY: 0
      })
      gsap.to(".hs-about-banner .hs-noir", {
        delay: .9,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
        stagger: .2,
        scaleY: 0
      })

      gsap.to(".hs-about-banner__container h1 span", {
        delay: 1,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
        y: 0,
        stagger: .2,
      })

      gsap.to(".hs-about-banner__container strong", {
        delay: .9,
        ease: "back.out",
        duration: 1.5,
        opacity: 1,
      })


      gsap.to(square, {
        height: 'calc(100% - 2em)',
        width: 'calc(100% - 2em)',
        // maxWidth: '100%',
        // y: "-30svh",
        ease: "none",
        scrollTrigger: {
          start: "top top",
          trigger: trigger,
          scroller: ".hs-main",
          pin: true,
          scrub: true,
          end: () => `+=100svh`,
        }
      })

    }
  }, [preloader])

  const [timer, setTimer] = React.useState(!animeHeader ? 7 : 1);
  const id = useRef(null);
  const clear = () => {
      // @ts-ignore
      window.clearInterval(id.current);
      setPreload(false);
  };

  useEffect(() => {
      // @ts-ignore
      id.current = window.setInterval(() => {
          setTimer((time) => time - 1);
      }, 1000);
      return () => clear();
  }, []);

  useEffect(() => {
      if (timer === 0) {
          clear();
      }
  }, [timer]);


  if (typeof window === "undefined" || !window.document) {
      return null;
  }

  return (
    <>
      {
        preloader ? (
          !animeHeader ? <Loader /> : null
        ) : (
          <main role='main' className='hs-main' data-scroll-container ref={ref} >
            <div className='hs-about'>
              <div className='hs-about-banner' onMouseEnter={() => hoverLink()} onMouseLeave={() => unhoverLink()} ref={container_banner}>
                <div className='hs-about-banner__image'>
                  <img src="/images/normal/imageBg3.jpg" alt="about"  data-scroll data-scroll-speed="-2"/>
                  <div className="hs-noir"></div>
                  <div className="hs-blanc"></div>
                </div>
                <div className='hs-about-banner__container'>
                  <strong>A propos de nous</strong>
                  <h1><span>VOUS AIDER À AMÉLIORER VOTRE</span>  <span>IMAGE DE MARQUE</span> </h1>
                </div>
              </div>
              <div className='hs-about-introduce'  data-scroll data-scroll-speed="3">
                  <div className='hs-about-introduce__container'>
                    <div className='hs-about-introduce__text'>
                        <p>
                        Hisia Studio est un studio basé à Ouagadougou, Burkina Faso; spécialisé dans l’accompagnement marketing et visuel des start-up, des PME, et des entreprises ou structures existantes.
                        </p>
                    </div>
                  </div>
              </div>
              <section className='hs-about__bigImage hs-about-bigImage'>
                  <div className='hs-about-bigImage__container' data-scroll data-scroll-speed="-2"></div>
              </section>

              <div className='panel'>
              <Service/>
              </div>
              <div className='panel'>
              <Expertise/>
              </div>
              <div className='panel'>
                <Emotion/>
              </div>
              <WeAre/>
              <Quality/>

              <div className='panel'>
              <Value/>
              </div>
              <Footer/>
            </div>
          </main>
        )
      }
    </>
  )
}
