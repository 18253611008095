import { ContactPage, ErrorPage, HisiaPage, HomePage, ProjectPage, ProjectsPage } from "../../pages"

export const links = {
    home: "/",
    studio: "/le-studio",
    contact: "/contacter-nous",
    works: "/nos-travaux",
    work: "/projet/",
    error: "/*",
}

export const routes = [
    {
        path: links.home,
        Component: HomePage
    },
    {
        path: links.studio,
        Component: HisiaPage
    },
    {
        path: links.contact,
        Component: ContactPage
    },
    {
        path: links.works,
        Component: ProjectsPage
    },
    {
        path: links.work + ":slug",
        Component: ProjectPage
    },
    {
        path: links.error,
        Component: ErrorPage
    }
]